import "./custom.scss"
import Calculator from "./Calculator"

function App() {
  return (
    <div className="App">
      <Calculator/>
    </div>
  );
}

export default App;
